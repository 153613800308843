.openlayerMap {
  width: 100%;
  height: 100%;
}
.btn {
  position: absolute;
  bottom: 40px;
  width: 50%;
  left: 25%;
  text-align: center;
  background-color: red;
  color: #fff;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
}
.mapName{
  position: absolute;
  top: 10px;
  font-size: 20px;
  z-index:999;
  text-align: center;
  width: 100%;
}